<template>
    <TransitionHeight
        v-if="important_notice"
        id="announcement-banner"
        ref="transitionHeightRef"
    >
        <div v-show="show" data-cy="announcement-banner" class="bg-gray-200">
            <div class="container">
                <div
                    class="container flex items-center px-0 py-4 md:py-6 lg:py-8"
                >
                    <div class="w-1/12">
                        <Icon
                            id="icon"
                            name="circle-exclamation-solid"
                            class="text-[30px] text-warning md:text-[40px] lg:text-[56px]"
                        ></Icon>
                    </div>
                    <div class="w-full">
                        <h3
                            class="pl-2 sm:pl-0"
                            data-cy="announcement-title"
                            v-html="important_notice.title"
                        ></h3>
                        <p
                            class="hidden md:inline-block"
                            data-cy="announcement-message"
                            v-html="important_notice.message"
                        ></p>
                    </div>
                    <div class="items-top flex flex-1 justify-end">
                        <button
                            type="button"
                            data-cy="announcement-close-btn"
                            @click="removeBanner"
                        >
                            <Icon
                                name="xmark"
                                class="text-[30px] md:text-[40px] lg:text-[45px]"
                                data-cy="announcement-close-btn-icon"
                            />
                        </button>
                    </div>
                </div>
                <div>
                    <p
                        class="w-full pb-4 md:hidden"
                        data-cy="announcement-message"
                        v-html="important_notice.message"
                    ></p>
                </div>
            </div>
        </div>
    </TransitionHeight>
</template>

<script setup lang="ts">
import siteNotification from '~/state/SiteNotification';

const { data: serverAnnouncements } = await useFetch(
    `/nuxt-api/global/important_notice`,
);

const important_notice = computed<[]>(() => {
    if (!serverAnnouncements.value || !serverAnnouncements.value.length) {
        return null;
    }
    const important_notice_data = serverAnnouncements.value[0];
    const title = important_notice_data.title;
    const message = important_notice_data.content;
    const today = new Date();
    const start_time = new Date(important_notice_data.start_time);
    const end_time = new Date(important_notice_data.end_time);

    if (today > start_time && today < end_time) {
        return { title: title, message: message };
    }

    return null;
});

const cookieName = 'announcementBannerAcknowledged';
const cookie = useCookie(cookieName, { maxAge: 24 * 60 * 60 });
const show = ref(false);
const transitionHeightRef = ref(null);

const removeBanner = () => {
    show.value = false;
    setTimeout(() => {
        cookie.value = 'true';
    }, 500);
};

watchEffect(() => {
    if (!cookie.value) {
        show.value = true;
    }
    if (show.value && siteNotification.value && transitionHeightRef.value) {
        transitionHeightRef.value.recalculateHeight();
    }
});
</script>
